
export default {
  components: {
    PostInternal: () => import('@/components/parts/news/PostInternal.vue'),
    PostExternal: () => import('@/components/parts/news/PostExternal.vue'),
  },
  props: {
    post: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    getComponent() {
      if (this.post.externalLink) return 'PostExternal'
      return 'PostInternal'
    },
  },
}
